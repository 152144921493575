import React, { MouseEventHandler } from "react";
import Image from "next/image";
import { Modal } from "@/components/Modal";


export const ModalComfirm = ({
  children,
  imgAssets,
  modalId,
  modal,
  handleClose,
  handleSubmit,
  headerTitle,
  custum,
  modalType = null,
  handleCancel = null,
  disabled = null,
  ...props
}: any ) => {

  if(modalType == 'custom')
    return (<Modal
            isDefaultShow={false}
            isDefaultShowOnRoute=''
            modalId={modalId || ''}
            modalSubType={ custum?.class || ''}
            modalTitle="Modal title"
          >
            <>
              { children }
            </>
          </Modal>)

  switch(modalId)
  {
    case 'implantSelection':
      return (
        <Modal
          isDefaultShow={false}
          isDefaultShowOnRoute=''
          modalId={modalId || ''}
          modalType={modal.modalTypes.selectModal}
          modalSubType=''
          modalTitle="Modal title"
        >
          <>
            <div className="modal-header">
              <h4 className="modal-title text_center">{ headerTitle || '' }</h4>
              <button style={{ zIndex: '99' }} className="close" type="button" data-request="close" data-modal={modalId} onClick={handleClose} ><Image src={imgAssets.iconClose} alt=""  /></button>
            </div>
            <div className="modal-body">
              { children }
            </div>
            <div className="modal-footer dflex dflex_end">
              <button className="btn btn-primary minw140" type="button" onClick={handleSubmit} >選択 </button>
            </div>
          </>
        </Modal>
      );
      break;
    case 'SelectionProduct':
    case 'Selection':
        return (
          <Modal
            isDefaultShow={false}
            isDefaultShowOnRoute=''
            modalId={modalId || ''}
            modalType={modal.modalTypes.selectModal}
            modalSubType=''
            modalTitle="Modal title"
          >
            <>
              <div className="modal-header">
                <h4 className="modal-title text_center">{ headerTitle || '' }</h4>
                <button style={{ zIndex: '99' }} className="close" type="button" data-request="close" data-modal={modalId} onClick={handleClose} ><Image data-request="close" data-modal={modalId} onClick={handleClose} src={imgAssets.iconClose} alt=""  /></button>
              </div>
              <div className="modal-body">
                { children }
              </div>
              <div className="modal-footer dflex dflex_end">
                <p className="text_bold mr60">{ custum?.items.length } { custum?.text }</p>
                <button className="btn btn-bdnone minw140 btn-deselect" data-request="close" data-modal={modalId} onClick={handleCancel} type="button">選択解除</button>
                <button className="btn btn-primary minw140 btn-select" type="button" disabled= { disabled } onClick={handleSubmit} >選択</button>
              </div>
            </>
          </Modal>
        );
        break;
    default:
      return (
        <Modal
          isDefaultShow={false}
          isDefaultShowOnRoute=''
          modalId={modalId || ''}
          modalType={modal.modalTypes.confirmModal}
          modalSubType='modalAlert'
          modalTitle="Modal title"
        >
          <>
            <div className="modal-body">
              { children }
            </div>
            <div className="modal-footer dflex">
              <button data-request="close" className="btn btn-outline-secondary minw140" type="button" onClick={handleClose} > キャンセル </button>
              <button
                className="btn btn-danger minw140"
                type="button"
                onClick={handleSubmit}
              >
                削除
              </button>
            </div>
          </>
        </Modal>
      );
      break;
  }
};
