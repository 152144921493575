import Router from "next/router"
import { HookContext, TContextHook } from "@/context/hook";
import React from "react";
import { number } from "yup";
import { setModal, setVariationGlobal } from "@/store/global";

export class Modal {

  private hooks: any;

  constructor ( hooks: any  = null) {

    if( hooks )
       this.hooks = hooks;
    else
      this.hooks = React.useContext(HookContext) as TContextHook;
  }

  public handleModle = (event : any) => {


    const request: string = event.target ? event.target.getAttribute("data-request")?.toString() : event?.request
    const modalId: string = event.target ? event.target.getAttribute("data-modal")?.toString() : event?.modal


    if( request == 'open' )
      return this.hooks.dispatch( setModal({ modalId, isShow: true, data: null }) )

    if( request == 'close' )
    return this.hooks.dispatch( setModal({ isCloseAll: true }) )

  }

  public handleModals = ( field : any, boleen = null) => {

      const modal = this.hooks.getState().global
      let data : any = ! modal[field] ? true: ! modal[field]

      this.hooks.dispatch( setVariationGlobal( { data: boleen != null ? boleen : data, field } ) )
  }

}

